// 用户名的校验方法
export const validateName = (rule, value, callback) => {
    let {message} = rule
    if (!value) {
        return callback(new Error(message))
    }
    // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
    const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/
    if (userNameRule.test(value)) {
        return callback()
    } else {
        return callback(new Error(message))
    }
}

// 密码的校验方法
export const validatePass = (rule, value, callback) => {
    let {message} = rule
    if (value == '') {
        return callback(new Error(message))
    }
    // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
    const passwordRule = /^[a-zA-Z]\w{5,17}$/
    if (passwordRule.test(value)) {
        return callback()
    } else {
        return callback(new Error(message))
    }
}

// 手机号的校验方法
export const validatePhone = (rule, value, callback) => {
    let {message} = rule
    if (value == '') {
        return callback(new Error(message))
    }
    const phoneRule = /^1[3-9]\d{9}$/
    if (phoneRule.test(value)) {
        return callback()
    } else {
        return callback(new Error(message))
    }
}

// 邮箱的校验方法
export const validateEmail = (rule, value, callback) => {
    let {message} = rule
    if (value == '') {
        return callback(new Error(message))
    }
    const emailRule = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (emailRule.test(value)) {
        return callback()
    } else {
        return callback(new Error(message))
    }
}
