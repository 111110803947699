<template>
<div class="newMyAddress">
  <div class="addressList" v-if="addressList.length">
    <div v-for="item in addressList" :key="item.id"  @click="selectAddress(item)" :class="item.id == activeId?'item active':'item default'">
      <div class="iconfont icon-dizhi dz"></div>
      <div class="name">{{item.deliveryName}}</div>
      <div class="detail">{{item.province}}{{item.city}}{{item.area}}{{item.detailAddress}}</div>
      <div class="phone">{{item.deliveryPhone}}</div>
      <div class="tag">
        <el-tag v-if="item.defaultAddress == 1" size="small">默认地址</el-tag>
      </div>
      <div class="action">
        <el-button type="text" @click="addAddress('edit',item)">修改</el-button>
        <el-button type="text" @click="delAddress(item.id)">删除</el-button>
      </div>
      <div v-if="item.id == activeId" class="iconfont icon-youxiajiaogouxuan right"></div>
    </div>
  </div>
  <div v-else class="plus">
    <div @click="addAddress('add',null)" class="iconfont icon icon-jiahao-dange"></div>
    <div class="empty">暂无地址</div>
  </div>
<!--  地址-->
  <add-address ref="addAddress" @confirm="getList"></add-address>
</div>
</template>

<script>
import {deliveryaddressList,deliveryaddressRemove} from '@/api/deliveryaddress/index'
import addAddress from "@/components/addAddress.vue";
export default {
  name:'newMyAddress',
  components:{addAddress},
  data(){
    return{
      activeId:'',
      addressList:[]
    }
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      deliveryaddressList().then(res=>{
        if(res.data.code == 200){
          this.addressList = res.data.data
          this.activeId = this.addressList.find(item=>item.defaultAddress == 1).id
        }else {
          this.$message.error(res.data.msg)
        }
      })
    },
    addAddress(type,info){
      this.$nextTick(()=>this.$refs.addAddress.openDialog(type,info))
    },
    selectAddress(item){
      this.activeId  = item.id
    },
    delAddress(id){
      this.$confirm("确定删除此收货地址吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        await deliveryaddressRemove({id}).then(res=>{
          if(res.data.code == 200){
            this.$message.success('删除成功')
            this.getList()
          }
        })
      })

    }

  }
}
</script>
<style scoped lang="scss">
.newMyAddress{
  .addressList{
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    .item{
      position: relative;
      padding: 0px;
      display: flex;
      align-items: center;
      div{
        margin: 0 20px;
      }
      .dz{
        font-size: 30px;
        color: #1e95d4;
      }
      .right{
        position: absolute;
        font-size: 40px;
        right: -21px;
        bottom: 0;
        color: #1e95d4;
      }
    }
    .active{
      border: 2px solid #1e95d4;
    }
    .default{
      border: 2px solid #EBEEF5;
    }
  }
  .plus{
    cursor: pointer;
    border: 2px solid #EBEEF5;
    border-top-color: transparent;
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    .icon{
      color: #6a737d;
      font-size: 60px;
    }
    .empty{
      color: #6a737d;
      font-size: 16px;
    }
  }
}
</style>