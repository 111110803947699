import Axios from 'axios'
// 官网实名认证 - 官网实名认证
export const officialUserAuth = (params) => Axios({
    url: '/zuwu-api/web/userAuth/officialUserAuth',
    method: 'get',
    params
});
// 官网实名认证 - 查询实名认证结果
export const getUserAuthResult = (params) => Axios({
    url: '/zuwu-api/web/userAuth/getUserAuthResult',
    method: 'get',
    params
});
// 官网实名认证 -  查询用户是否实名认证信息
export const isAuthentication = (params) => Axios({
    url: '/zuwu-api/web/userAuth/isAuthentication',
    method: 'get',
    params
});