
<template>
<div class="publicPayment">
  <div class="title">您已申请对公支付:</div>
  <div class="content">
    <div class="item">开户名称：武汉问道供应链管理有限公司</div>
    <div class="item">开户银行：平安银行股份有限公司武汉百步亭支行</div>
    <div class="item">公司对公账户：15059690080052</div>
    <div class="item">请仔细核对金额和账户！若有疑问请联系您的专属客户经理或拨打电话：13701608904</div>
    <div class="item">合同已发送至手机号 152****6758，请点击短信链接完成签署，以免影响您的订单审核进程。</div>
  </div>
  <div class="action">
    <el-button type="text" @click="replenish">补充资料</el-button>
    <el-button type="text" @click="voucher">上传凭证</el-button>
    <el-button type="text" @click="goHome">返回首页</el-button>
    <el-button type="text" @click="goOrderDetail">查看订单</el-button>
  </div>
  <paymentVoucher ref="paymentVoucher"></paymentVoucher>
</div>
</template>
<script>
import paymentVoucher from "./paymentVoucher.vue"
export default {
  name:'publicPayment',
  components:{paymentVoucher},
  props:['isEnterpriseOrder'],
  data(){
    return{

    }
  },
  methods:{
    voucher(){
      this.$refs.paymentVoucher.openModel()
    },
    goHome(){
      this.$router.push({
        path: '/home',
      })
    },
    goOrderDetail(){
      this.$router.push({
        path: '/User',
        query:{key:'2-1'}
      })
    },
    replenish(){
      if (this.isEnterpriseOrder) {
        this.$router.push({ path: '/Enterprise' })
      } else {
        this.$router.push({ path: '/Collating' })
      }
    }
  }
}

</script>

<style scoped lang="scss">
.publicPayment{
  max-width: 1430px;
  margin: 30px auto;
  padding: 20px;
  font-size: 16px;
  background: #fff;
  .title{
    font-size: 28px;
    font-weight: bold;
  }
  .content{
    padding: 20px;
    margin: 10px 0;
    .item{
      margin-bottom: 20px;
    }
  }
  .action{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    margin: 10px auto;
  }
}

</style>