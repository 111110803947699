import Axios from 'axios'

// 下单
export const addOrderUserLeasePattern = (data) => Axios({
    url: '/zuwu-api/web/order/add/order/userLeasePattern',
    method: 'post',
    dataType: 'json',
    data
});
// 我的订单 - 我的订单列表
export const orderList = (params) => Axios({
    url: '/zuwu-api/web/orderInfo/list',
    method: 'get',
    params
});
// 我的订单 - 订单详情
export const orderDetail = (orderDetailId) => Axios({
    url: `/zuwu-api/web/order/detail/${orderDetailId}`,
    method: 'get',
});
// 我的订单 - 订单取消
export const orderCancel = (params) => Axios({
    url: `/zuwu-api/web/order/cancel`,
    method: 'get',
    params
});
// 获取合同链接
export const getContractUrl = (data) => Axios({
    url: '/zuwu-api/web/contract/getContractUrl',
    method: 'post',
    dataType: 'json',
    data
});
// 我的订单 - 我的账单列表
export const billList = (params) => Axios({
    url: '/zuwu-api/web/orderInfo/billList',
    method: 'get',
    params
});
// 我的订单 - 总计应还
export const shouldPay = (params) => Axios({
    url: '/zuwu-api/web/orderInfo/shouldPay',
    method: 'get',
    params
});
// 我的订单 - 全部账单列表
export const checkAllBill = (params) => Axios({
    url: '/zuwu-api/web/orderInfo/checkAllBill',
    method: 'get',
    params
});

// 获取全部发票
export const selectAllInvoiceInfoPage = (data) => Axios({
    url: '/zuwu-api/web/orderInfo/selectAllInvoiceInfoPage',
    method: 'post',
    dataType: 'json',
    data
});

// 开票申请
export const applyForInvoice = (data) => Axios({
    url: '/zuwu-api/web/orderInfo/applyForInvoice',
    method: 'post',
    dataType: 'json',
    data
});

// 我的发票 - 申请重发
export const regenerateInvoice = (params) => Axios({
    url: '/zuwu-api/web/orderInfo/regenerateInvoice',
    method: 'get',
    params
});

// 我的发票 - 催一催
export const invoiceUrge = (params) => Axios({
    url: '/zuwu-api/web/orderInfo/invoiceUrge',
    method: 'get',
    params
});

// 我的发票 - 发票下载
export const invoiceDownload = (params) => Axios({
    url: '/zuwu-api/web/orderInfo/invoiceDownload',
    method: 'get',
    params
});