
<template>
  <div class="paySuccess">
    <div class="main">
      <div class="item">订单已提交，请在十五分钟内扫码完成支付
        <span class="red">14:59:59</span>
      </div>
      <div class="item">待付金额：<span class="red">256.00</span> 元</div>
      <div class="order">
        订单编号：GLS20241105111301
      </div>
    </div>
    <div class="detail">
      <el-button type="text"> 查看订单详情>></el-button>
    </div>
  </div>
</template>
<script>
export default {
  name:'paySuccess'
}
</script>

<style scoped lang="scss">
.paySuccess{
  max-width: 1430px;
  margin: 30px auto;
  padding: 20px;
  font-size: 26px;
  background: #fff;
  .main{
    .red{
      color: red;
    }
    .item{
      margin-bottom: 20px;
    }
    .order{
      font-size: 16px;
      color: #5e5e5e;
    }
  }
  .detail{
    margin-top: 20px;
  }
}
</style>