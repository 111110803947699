<template>
<div class="addAddress">
  <el-dialog
      title="收货地址"
      :visible.sync="addressShow"
      width="40%"
      :append-to-body="true"
      :close-on-click-modal="false"
  >
    <el-form :model="formData" :rules="rules" ref="formData" size="small" label-width="100px">
      <el-form-item label="收货人姓名" prop="deliveryName">
        <el-input v-model="formData.deliveryName" placeholder="请输入收货人姓名" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="deliveryPhone">
        <el-input v-model="formData.deliveryPhone" placeholder="请输入手机号码" clearable></el-input>
      </el-form-item>
      <el-form-item label="省/市/区" prop="provinceData">
        <el-cascader v-model="formData.provinceData"
            :options="area" placeholder="请选择省/市/区"  ref="cascaderArr"
                     @change="handleChange($event)"  clearable style="width: 100%">
        </el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="detailAddress">
        <el-input type="textarea" :rows="3" clearable placeholder="请输入内容" v-model="formData.detailAddress"></el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="formData.defaultAddress">设为默认地址</el-checkbox>
      </el-form-item>
    </el-form>
    <div class="submit">
      <el-button size="small"  @click="closeDialog">取消</el-button>
      <el-button size="small" type="primary"  @click="submit">保存收货地址</el-button>
    </div>
  </el-dialog>
</div>
</template>
<script>
import {deliveryaddressUpdate} from '@/api/deliveryaddress/index'
import {validatePhone} from "@/util/validator";
import area from "../util/area.js";
export default {
  name:'addAddress',
  data(){
    return {
      checkedNode:[],
      area,
      addressShow: false,
      formData: {},
      config:{
        type:"add",
        title:'新增收货地址'
      },
      rules: {
        deliveryName: [{required: true, message: '请输入收货人姓名', trigger: 'blur'}],
        deliveryPhone:  [{ required: true, trigger: 'blur', message: '请输入正确的手机号', validator: validatePhone}],
        provinceData: [{required: true, message: '请选择省/市/区', trigger: 'blur'}],
        detailAddress: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
      }
    }
  },
  methods:{
    openDialog(type, info){
      this.config.type = type
      this.config.title = type == 'add'?'新增收货地址':'修改收货地址'
      this.initData(info)
      this.addressShow = true
    },
    handleChange() {
      const checkedNode =  this.$refs["cascaderArr"].getCheckedNodes();
      this.checkedNode = checkedNode[0].pathLabels
    },
    submit(){
      this.$refs.formData.validate(valid=>{
        if(valid){
          if (this.checkedNode && this.checkedNode.length == 3) {
            this.formData.province = this.checkedNode[0]
            this.formData.city = this.checkedNode[1]
            this.formData.area = this.checkedNode[2]
          }
          if (this.formData.provinceData.length == 3) {
            this.formData.provinceCode = this.formData.provinceData[0]
            this.formData.cityCode = this.formData.provinceData[1]
            this.formData.areaCode = this.formData.provinceData[2]
          }
          this.formData.defaultAddress =  this.formData.defaultAddress?1:0
          let fun  = deliveryaddressUpdate
          if(this.config.type == 'edit'){
            fun = deliveryaddressUpdate
          }
          fun(this.formData).then(res=>{
            if(res.data.code == 200){
              this.$message.success(`${this.config.type == 'add'?'新增':'修改'}成功`)
              this.$emit('confirm')
              this.closeDialog()
            }
          })
        }
      })
    },
    initData(info){
      if(this.config.type == 'add'){
        this.formData = {}
      }else{
        console.log(info)
        const {defaultAddress,provinceCode,cityCode,areaCode} = info
        this.formData = {...info,defaultAddress:defaultAddress == 1,provinceData:[provinceCode,cityCode,areaCode]}
      }

    },
    closeDialog(){
      this.addressShow = false
      this.formData = {}
    }
  }
}
</script>
<style scoped>
.submit{
  margin: 10px 0;
  text-align: right;
}
</style>