<template>
  <div class="confirmOrder">
    <MyMenu :isAuth="isAuth" :stepIndex="isPayment || isCorporate?1:0" :menuname="'place'"></MyMenu>
    <!-- 头部 -->
<!--    <Stepbar :active="0" :enterprisevalue="enterprisevalue"></Stepbar>-->

    <!-- 主要内容容器 -->
    <div class="content" v-if="!isCorporate && !isPayment">
      <!-- 选择地址 -->
      <div class="section-item">
        <p class="title">收货地址</p>
<!--        <MyAddress @addressnum="addressnum"></MyAddress>-->
        <newMyAddress ref="newMyAddress"></newMyAddress>
      </div>
      <!-- 选择地址END -->
      <!-- 商品及优惠券 -->
      <div class="section-item">
        <p class="title">确认订单信息</p>
        <div class="table">
          <el-table size="small" border :data="goodsData" :header-cell-style="{'text-align':'center', 'background': '#f5f5f5'}" :cell-style="{'text-align':'center'}" style="width: 100%">
            <el-table-column prop="date" width="500" label="商品信息">
              <template slot-scope="">
                <div class="orderInfo">
                  <div class="img">
                    <img :src="currentCommodityDetail.skuImage">
                  </div>
                  <div class="info">
                    <div class="item name">{{ currentCommodityDetail.title }}</div>
                    <div class="item">【{{ currentCommodityDetail.properties[0].propertyValue }}】</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="300" prop="address" label="单价">
              <template slot-scope="">
                <div class="monthRent">
                  <p class="month">月租金：</p>
                  <span class="pro-monthrent">
                    <p v-if="currentCommodityDetail.rent_1_12 * 1 > 0">1-12 期月租金: ￥{{ currentCommodityDetail.rent_1_12 }}</p>
                    <p v-if="currentCommodityDetail.rent_13_24 * 1 > 0">13-24 期月租金: ￥{{ currentCommodityDetail.rent_13_24 }}</p>
                    <p v-if="currentCommodityDetail.rent_25_36 * 1 > 0">25-36 期月租金: ￥{{ currentCommodityDetail.rent_25_36 }}</p>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="数量">
              <template slot-scope="">
                <el-input-number size="mini" v-model="commodityNumber" @change="handleChange" :min="1" :max="99"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column width="200" prop="address" label="合计">
              <template slot-scope="">
                <p>首期应付：￥{{currentCommodityDetail.advancePayRent}}</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="tips">
          <div>
            <el-tag type="danger">租后归还</el-tag>
            实际租赁期大于免责月时，无需支付违约金；实际租期小于免责月时，客户需要承担违约金。
            审核通过的订单会在24小时内安排发出，请耐心等待，如有紧急需求请及时联系您的客户经理
            实际租期12个月(不含)以内的，运费是一方一程(出租方承担寄出费用，客户承担寄回费用)，实际租期12个月(含)以上的，巨悠租承担来回运费。
            <p class="won">
              详情请查看协议说明-- <el-link type="primary" @click="goUser('1-3-2')">《下单须知》</el-link>
            </p>
          </div>
        </div>
      </div>
      <!-- 支付方式 -->
      <div class="section-item">
        <p class="title">支付方式</p>
        <div>
          <el-radio-group v-model="paymentType">
            <el-radio label="1" border>线上支付</el-radio>
            <el-radio label="2" border>线下对公</el-radio>
          </el-radio-group>
        </div>
      </div>
      <!-- 买家留言 -->
      <div class="section-item">
        <p class="title">订单备注</p>
        <el-input type="textarea" class="desc" placeholder="填写您对订单的其他要求" v-model="orderRemark"></el-input>
      </div>
      <!-- 结算导航 -->
      <div class="section-bar">
        <div class="Read-protocol">
          <el-checkbox v-model="checked">我已同意并阅读</el-checkbox>
          <el-link type="primary" @click="goUser('1-2-3')">《用户信息查询授权书》</el-link>
          <el-link type="primary" @click="goUser('1-2-4')">《隐私政策》</el-link>
          <el-link v-if="enterprisevalue == 1" type="primary" @click="goUser('1-2-1')">《租赁服务协议》</el-link>
          <el-link v-else type="primary" @click="goUser('1-2-2')">《租赁服务协议》</el-link>
        </div>
        <div class="btn">
          <div class="priceTotal">
            <p class="title">总计应付：</p>
            <p class="price">￥{{ dealNumber(currentCommodityDetail.advancePayRent * commodityNumber) }}</p>
          </div>
          <el-button @click="addOrder(1)" :disabled="!checked" type="primary" class="btn-primary">个人租赁</el-button>
          <el-badge class="btn-primary" :value="'只需支付首期费用' + dealNumber(currentCommodityDetail.advancePayRent * commodityNumber)">
            <el-button @click="addOrder(0)" :disabled="!checked" type="primary">企业租赁</el-button>
          </el-badge>
        </div>
      </div>
      <!-- 结算导航END -->
    </div>
    <!-- 对公支付 -->
    <publicPayment :isEnterpriseOrder="currentCommodityDetail.isEnterpriseOrder" v-if="isCorporate"></publicPayment>
    <!--立即支付-->
    <paySuccess v-if="isPayment"></paySuccess>
  </div>
</template>
<script>
import {addOrderUserLeasePattern} from "@/api/order";
import {isAuthentication} from '@/api/userAuth/index'
import publicPayment from "@/views/Order/item/publicPayment.vue";
import paySuccess from "@/views/Order/item/paySuccess.vue";
import Stepbar from './item/Stepbar.vue'
import MyAddress from '../../components/MyAddress.vue'
import newMyAddress from '../../components/newMyAddress.vue'
export default {
  name: 'newConfirmOrder',
  // eslint-disable-next-line vue/no-unused-components
  components: { MyAddress, Stepbar, newMyAddress,publicPayment,paySuccess},
  data() {
    return {
      isPayment:false,
      isCorporate:false,
      paymentType:'1',
      goodsData:[],
      checked: false,
      enterprisevalue: 0, //企业or个人
      expectDeliveryDate: '',
      orderRemark: '',
      startDate: '',
      endDate: '',
      adChannel: '',
      commodityNumber: 1,
      currentCommodityDetail: {},
      isAuth: false
    }
  },
  created() {
    var currentCommodityDetail = JSON.parse(localStorage.getItem('currentCommodityDetail'))
    if (currentCommodityDetail) {
      this.currentCommodityDetail = currentCommodityDetail
      this.goodsData[0] = currentCommodityDetail
      this.commodityNumber = currentCommodityDetail.commodityNumber
      this.checkIsAuthentication()
    } else {
      this.goback()
    }
  },
  computed: {
    // 商品详情
    datePickerOptions() {
      var _this = this
      let startDate
      let endDate
      // 开始为当前日期判断4点前和4点后
      const currentDate = new Date() // Get the current date and time
      const currentHour = currentDate.getHours() // Get the current hour (in 24-hour format)
      // 1天
      var d = 1000 * 60 * 60 * 24
      if (currentHour > 16) {
        startDate = currentDate.getTime() + d
      } else {
        startDate = currentDate.getTime()
      }
      // 如果是预售 加7天
      if (_this.currentCommodityDetail.presaleCommodity == 1) {
        startDate = currentDate.getTime() + d * 7
      }
      // 结束为当前日期的3个月后
      endDate = currentDate.getTime() + d * 90

      var time = new Date(startDate)
      var year = time.getFullYear() //年
      var month = ('0' + (time.getMonth() + 1)).slice(-2) //月
      var day = ('0' + time.getDate()).slice(-2) //日
      var expectDeliveryDate = year + '-' + month + '-' + day
      _this.expectDeliveryDate = expectDeliveryDate

      return {
        disabledDate(time) {
          return time.getTime() < startDate - d || time.getTime() > endDate
        }
      }
    }
  },

  methods: {
    // 选择支付方式
    changePayType(value){
      if(value == '2'){
        this.$confirm("确定选择对公支付?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          this.isCorporate = true
        }).catch(()=>{
          this.paymentType = '1'
        })
      }
    },
    goUser(key) {
      this.$router.push({
        path: '/User',
        query:{key}
      })
    },
    // goback
    goback() {
      this.$router.go(-1) //返回上一页
    },

    handleChange(value) {
      this.commodityNumber = value
    },

    // 企业or个人
    switchto(val) {
      this.enterprisevalue = val
    },
    checkIsAuthentication() {
      isAuthentication().then((res) => {
        if (res.data.code == 200) {
          this.isAuth = true
        } else {
          this.isAuth = false
        }
      })
    },

    addOrder(type) {
      this.enterprisevalue = type || 0
      // 企业订单判断框架协议是否签署
      if (this.isAuth || this.enterprisevalue == 0) {
        if (this.$refs.newMyAddress.activeId) {
          let data = {
            adChannel:this.currentCommodityDetail.adChannel ,
            commodityNumber: this.currentCommodityDetail.commodityNumber,
            expectDeliveryDate: this.currentCommodityDetail.expectDeliveryDate,
            isEnterpriseOrder: this.enterprisevalue == 0 ? true : false,
            lat: '',
            leasePatternId:this.currentCommodityDetail.leasePatternId,
            lng: '',
            orderRemark:this.orderRemark ,
            shareOpenId: this.currentCommodityDetail.shareOpenId,
            skuId: this.currentCommodityDetail.commoditySkuId
          }
          if(type == 1){
            addOrderUserLeasePattern(data).then(res=>{
              if(res.data.code == 200){
                if(this.paymentType == '2'){
                  this.isCorporate = true
                }
                if(this.paymentType == '1'){
                  this.isPayment = true
                }
              }
            })
          }else {
            this.$router.push({ path: '/Enterprise' })
          }

          // 商品详情存储
          localStorage.setItem('currentCommodityDetail', JSON.stringify(this.currentCommodityDetail))

          // if (this.currentCommodityDetail.isEnterpriseOrder) {
          //   this.$router.push({ path: '/Enterprise' })
          // } else {
          //   this.$router.push({ path: '/Collating' })
          // }
        } else {
          this.$message({
            type: 'warning',
            message: '请先选择收货地址'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请先实名认证'
        })
        // setTimeout(() => {
        //   this.$router.push({ path: '/User/realname' })
        // }, 300)
      }
    },

    dealNumber(num) {
      if (num != 0 && num) {
        num = num + ''
        let decimalsStr = ''
        let splitList = num.split('.')
        //先处理小数部分
        if (splitList[1]) {
          //如果有2位小数则保留2位，只有1位则添0
          decimalsStr = splitList[1].substring(0, 2).length == 2 ? splitList[1].substring(0, 2) : splitList[1].substring(0, 2) + '0'
          // if (decimalsStr)
        } else {
          decimalsStr = '00'
        }
        //整数部分
        //将整数部分拆解为单个数字的数组倒序，然后拼装为新的数组，每3位数字进行一次匹配
        let intStrList = splitList[0]
            .split('')
            .reverse()
            .join('')
            .match(/(\d{1,3})/g)
        //将匹配后的数组用，拼接，再拆解为单个数字的数组，反转为原先数组形式，拼接为完整数字
        let intStr = intStrList
            .join(',')
            .split('')
            .reverse()
            .join('')
        return intStr + '.' + decimalsStr
      }
      return num
    }
  }
}
</script>
<style scoped lang="scss">
.confirmOrder {
  background-color: #f5f5f5;
  padding-bottom: 20px;
}
/* 主要内容容器CSS */
.confirmOrder .content {
  width: 1430px;
  margin: 20px auto;
  //padding: 30px 0 0;
  background-color: #fff;
}

/* 展示列表统用样式 */
.section-item {
  margin: 0 48px;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}
.section-item .title {
  color: #333;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
  width: 150px;
}
.section-item .goods-list {
  width: 100%;
  overflow: hidden;
}
.section-item .goods-list li {
  padding: 10px 0;
  color: #424242;
  overflow: hidden;
}
.section-item .goods-list li img {
  float: left;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.section-item .goods-list li .pro-name {
  float: left;
  width: 330px;
  padding-top: 15px;
  line-height: 25px;
  font-size: 16px;
}
.section-item .goods-list li .pro-price {
  float: left;
  font-size: 14px;
  width: 400px;
  padding-top: 20px;
  line-height: 40px;
  text-align: center;
}
.section-item .goods-list li .pro-status {
  float: left;
  width: 100px;
  height: 40px;
  text-align: center;
  padding-top: 20px;
  line-height: 40px;
}
.section-item .goods-list li .pro-monthrent {
  float: left;
  width: 170px;
  font-size: 12px;
  text-align: right;
  padding-top: 10px;
  line-height: 20px;
}
.section-item .goods-list li .pro-monthrent i {
  display: block;
  font-style: normal;
}

.section-item .goods-dsc {
  background: #e0e0e0;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
}
.section-item .goods-dsc .dsc-title {
  float: left;
  width: 450px;
}
.section-item .goods-dsc .dsc-details {
  float: left;
  width: 400px;
}
.section-item .goods-dsc .dsc-month {
  float: left;
  width: 100px;
}
.section-item .goods-dsc .dsc-rent {
  float: left;
  width: 170px;
}

.section-item .shipment {
  float: left;
  line-height: 38px;
  font-size: 14px;
  background: #f7fcff;
  color: #1e95d4;
  padding: 0 10px;
  margin-right: 10px;
  border: 1px solid #1e95d4;
}

.section-item .delivery {
  float: left;
  line-height: 38px;
  font-size: 14px;
  color: #666;
}
.section-item .delivery a {
  color: #1e95d4;
  cursor: pointer;
}

.section-item .desc {
  float: left;
  width: 100%;
}

.section-item .invoice {
  float: left;
  line-height: 38px;
  font-size: 14px;
  padding: 0 20px;
  margin-right: 20px;
  color: #333;
  border: 1px solid #e0e0e0;
}
.section-item .hover {
  color: #1e95d4;
  background: #f7fcff;
  border: 1px solid #1e95d4;
}
/* 发票CSS END */

/* 结算列表CSS */
.section-count {
  margin: 0 48px;
  padding: 20px 0;
  overflow: hidden;
}
.section-count .money-box {
  float: right;
  text-align: right;
}
.section-count .money-box .title {
  float: left;
  width: 126px;
  height: 30px;
  display: block;
  line-height: 30px;
  color: #757575;
}
.section-count .money-box .value {
  float: left;
  min-width: 200px;
  height: 30px;
  display: block;
  line-height: 30px;
  color: #1e95d4;
}
.section-count .money-box .total .title {
  padding-top: 15px;
}
.section-count .money-box .total .value {
  padding-top: 10px;
}
.section-count .money-box .total-price {
  font-size: 30px;
}
/* 结算列表CSS END */

/* 结算导航CSS */
.section-bar {
  padding: 20px 48px 50px;
  //border-top: 2px solid #f5f5f5;
  overflow: hidden;
}
.section-bar .btn {
  float: right;
  display: flex;
  align-items: center;
  .priceTotal{
    display: flex;
    .price{
      font-size: 18px;
      color: red;
    }

  }
}

.section-bar /deep/ .el-button {
  padding: 12px 50px;
  margin-left: 20px;
}

.section-bar /deep/ .el-badge__content.is-fixed {
  right: 120px;
}

.Read-protocol {
  text-align: right;
  padding-bottom: 20px;
  font-size: 14px;
  color: #999;
}

.delivery /deep/ .el-input__inner {
  border: none;
  color: #ff5b50;
  cursor: pointer;
  font-weight: bold;
}
.table{
  ::v-deep .el-button{
    margin-left: 0;
  }
  .orderInfo{
    display: flex;
    img{
      width: 100px;
      height: 100px;
      margin-right: 20px;
    }
    .info{
      width: 350px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .monthRent{
    display: flex;
    .month{
      width: 60px;
    }
  }
  .pagination{
    margin-top: 20px;
    text-align: center;
  }
}
.tips{
  font-size: 14px;
  color: #6a737d;
  margin-top: 20px;
  .won{
    margin-top: 10px;
  }
}
::v-deep .el-radio__input{
  display: none;
}
</style>
