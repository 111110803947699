import Axios from 'axios'

// 通过id查询用户收货地址
export const deliveryaddressEcho = (params) => Axios({
    url: '/zuwu-api/web/deliveryaddress/echo',
    method: 'get',
    params
});
// 查询用户收货地址
export const deliveryaddressList = (params) => Axios({
    url: '/zuwu-api/web/deliveryaddress/page',
    method: 'get',
    params
});

// 删除用户收货地址
export const deliveryaddressRemove = (params) => Axios({
    url: '/zuwu-api/web/deliveryaddress/remove',
    method: 'get',
    params
});

// 修改用户收货地址
export const deliveryaddressUpdate = (data) => Axios({
    url: '/zuwu-api/web/deliveryaddress/update',
    method: 'post',
    dataType: 'json',
    data
});
// 新增用户收货地址
export const deliveryaddressSave = (data) => Axios({
    url: '/zuwu-api/web/deliveryaddress/save',
    method: 'post',
    dataType: 'json',
    data
});
