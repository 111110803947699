<template>
<div class="paymentVoucher">
  <el-dialog title="上传支付凭证" width="800px"  @close="closeModel" :visible.sync="modelShow">
    <Upload-item @handleChange="importFile" :fileList="fileUrlList" @ondelete="(i) => onDelete(i)"></Upload-item>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeModel">取消</el-button>
      <el-button size="small" type="primary" @click="submitModel">确认</el-button>
    </div>
  </el-dialog>
</div>
</template>
<script>
export default {
  name:'paymentVoucher',
  data(){
    return{
      modelShow:false,
      fileUrlList:[]
    }
  },
  methods:{
    openModel(){
      this.modelShow = true
    },
    closeModel(){
      this.modelShow = false
    },
    onDelete(index){
      this.fileUrlList.splice(index,1)
    },
    importFile(val){
      this.fileUrlList.push(val)
    },
    submitModel(){
      this.closeModel()
    }
  }
}
</script>

<style scoped>

</style>